import {
  DashboardResponsiveView,
  DashboardSelectPopover,
  DashboardToolbar,
  DashboardView,
  SharedContextProvider,
  useDashboard,
} from '@capturi/dashboard'
import { FilterPeriodProvider, PeriodDefinitions } from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { useToast } from '@capturi/ui-components'
import { Box, Flex, Heading, StyleProps, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { withPeriodSyncToSearchParams } from 'features/filters/filter-period-enhancers'
import React, { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import routes from './routes'

const FilterPeriodProviderWithSync = withPeriodSyncToSearchParams(
  FilterPeriodProvider,
  'dashboard_period',
)

type Props = {
  responsive?: boolean
}

const fullScreenProps: StyleProps = {
  backgroundColor: 'white',
  px: 4,
  py: 2,
}

export default function DashboardViewPage(
  props: Props,
): React.ReactElement | null {
  const params = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const { dashboard } = useDashboard(
    {
      uid: params.uid,
      onDashboardNotFound: () => {
        toast({
          status: 'error',
          title: t`Dashboard not found`,
        })
        navigate(routes.list(), { replace: true })
      },
    },
    {
      refreshInterval: 60_000,
    },
  )
  usePageTitle(dashboard?.title || '')

  const mainRef = useRef<HTMLDivElement>(null)
  return (
    <FilterPeriodProviderWithSync defaultPeriod={PeriodDefinitions.Last30Days}>
      <SharedContextProvider defaultType={dashboard?.sharedContextType}>
        <Flex
          ref={mainRef}
          _fullScreen={fullScreenProps}
          direction="column"
          w="100%"
          h="100%"
          flex={1}
        >
          <Flex as="header" wrap="wrap" align="center" flex="0 0 auto" pb={2}>
            <Box>
              <DashboardSelectPopover
                dashboard={dashboard ?? undefined}
                isSelectionDisabled={props.responsive}
                onSelectDashboard={(dashboard) => {
                  navigate(routes.view(dashboard.uid))
                }}
              >
                <Heading fontSize="3xl" as="h1">
                  {dashboard?.title}
                </Heading>
              </DashboardSelectPopover>
              <Text lineHeight={1} color="textMuted">
                {dashboard?.description}
              </Text>
            </Box>
            <Box ml="auto">
              <DashboardToolbar fullscreenElementRef={mainRef} />
            </Box>
          </Flex>
          <Box as="main" minH="300px" w="100%" flex={1} pos="relative">
            <Box
              w="full"
              h="full"
              position="absolute"
              overflow="visible"
              className="dashboard-container"
            >
              {dashboard != null &&
                (props.responsive ? (
                  <DashboardResponsiveView dashboard={dashboard} />
                ) : (
                  <DashboardView dashboard={dashboard} />
                ))}
            </Box>
          </Box>
        </Flex>
      </SharedContextProvider>
    </FilterPeriodProviderWithSync>
  )
}
